import { ApolloError } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useParams } from 'react-router-dom';
import { APP_URLS, locale, xTenantId } from '../../../../consts';
import { useFetchPromoDetails } from '../../../../hooks/useFetchPromoDetails';
import { NavigationTemplate } from '../../../../layouts/NavigationTemplate';
import {
  UserBonusesQuery,
  useUserBonusesLazyQuery,
} from '../../../../libs/graphql/baseAppAPI/queries/__generated__/user-promo-collection.generated';
import { BonusGetBySlugQuery } from '../../../../libs/graphql/betzoneDirectusAPI/queries/__generated__/bonus-get-by-slug.query.generated';
import { Endpoint } from '../../../../models/api.model';
import { useUserContext } from '../../../../providers/UserProvider';
import { UserProfileBonusStatus } from '../../../../types.__generated__';
import { RecommendedGameCarousel } from '../../../Games';
import { ButtonBack } from '../../../shared/ButtonBack';
import { Icon } from '../../../shared/Icon';
import { Paragraph } from '../../../shared/Paragraph';
import { Spinner } from '../../../shared/Spinner';
import { PromotionCard } from '../PromotionCard';
import { PromotionMessagesPrompt } from '../PromotionMessagesPrompt';
import { GigPromo } from '../types';
import PromotionHowItWorksBlock from './components/PromotionHowItWorksBlock';
import { getImageUrl } from '../../../../utils/navigation';
import DOMPurify from 'dompurify';
import { Heading } from '../../../shared/Heading';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { PromotionGames } from '../../../Games/games.model';
import './styles';
import { SEO } from '../../../seo/SEO';

export const PromotionMainBlock: FC = () => {
  const { t } = useTranslations();
  const location = useLocation();
  const { userProfileId } = useUserContext();
  const isUserAuthenticated = useAuth()?.isAuthenticated;
  const { pathname: pageUrl, hash } = location;

  const isTermsPage = hash === APP_URLS.terms;
  const promotionSlug = String(useParams().id);

  const [promotion, setPromotion] = useState<BonusGetBySlugQuery | null>(null);
  const [gigPromotion, setGigPromotion] = useState<GigPromo | null>(null);
  const [showPromoPrompt, setShowPromoPrompt] = useState<boolean>(false);

  const isActive = ([UserProfileBonusStatus.InProgress, UserProfileBonusStatus.Claimed] as string[]).includes(
    String(gigPromotion?.status)
  );
  const promo = promotion?.bonus[0];
  const {
    template_id,
    start_date,
    end_date,
    translations,
    eligible_games: eligibleGames,
    dm_template_id: dmBonusId,
    dm_max_amount: dmMaxAmount,
  } = promo || {};
  const resTransl = translations?.[0];

  const title = resTransl?.title || '';
  const description = resTransl?.subtitle || '';
  const termsDescr = resTransl?.description || '';
  const imgPath = getImageUrl(resTransl?.thumbnail?.filename_disk);
  const imgAlt = resTransl?.thumbnail?.title || '';
  const terms = resTransl?.terms_and_conditions;
  const instructions = resTransl?.instructions;

  // Mocks are partially applied here due to pending all items from CMS
  const parsedPromo = {
    promoCode: 'test-code',
    promoButtonText: t('promotions.button.claim.text'),
    promotionCTAType: 'DepositJourney',
    bonusId: template_id || '',
    startDate: start_date || '',
    endDate: end_date || '',
    title,
    description,
    termsDescr,
    imgPath,
    imgAlt,
    pageUrl,
    hideTerms: isTermsPage,
    dmMaxAmount: Number(dmMaxAmount),
    dmBonusId: String(dmBonusId),
  };

  const mergedPromo = { ...parsedPromo, ...gigPromotion };

  const handleGigPromos = (data: UserBonusesQuery): void => {
    const gigBonuses = data.userBonuses.data;

    console.log(gigBonuses);

    const gigPromo = (gigBonuses as GigPromo[])?.find(
      (gigBonus: GigPromo) =>
        parsedPromo.bonusId === gigBonus?.bonusTemplateId &&
        (gigBonus?.status === 'Claimed' || gigBonus?.status === 'Active')
    );

    gigPromo && setGigPromotion(gigPromo);
  };

  const { fetchBonusDetailsBySlug, fetchedBonusDetailsBySlugLoading } = useFetchPromoDetails({
    // TODO: replace hardcoded language value with dynamic
    language: locale,
    slug: promotionSlug,
    onFetchBonusBySlugComplete: setPromotion,
  });

  const [fetchBonusesGig, { loading: userBonusesLoading }] = useUserBonusesLazyQuery({
    variables: {
      xTenantId,
      userProfileId,
      pageLimit: 1000,
    },
    onCompleted: handleGigPromos,
    onError: (error: ApolloError) => console.error(error),
    context: { endpoint: Endpoint.appAPI },
  });

  useEffect(() => {
    (async (): Promise<void> => {
      await fetchBonusDetailsBySlug();
      isUserAuthenticated && (await fetchBonusesGig());
    })();
  }, []);

  return (
    <NavigationTemplate paddingDisabled>
      <SEO canonicalPath={pageUrl} />
      <div className="promotion-main-wrapper">
        {fetchedBonusDetailsBySlugLoading || userBonusesLoading ? (
          <Spinner />
        ) : promo ? (
          <>
            <div className="button-left">
              <ButtonBack
                position="left"
                icon={<Icon variant="ArrowLeft" />}
                type="button"
                size="x-large"
                variant="text"
                text={t('buttons.back.uppercase')}
                siteLink="pagePromotionsLobby"
              />
            </div>
            <div className="promotion-main-wrapper__info-block">
              <PromotionCard
                withoutMargin
                promo={mergedPromo}
                showCTASection={isActive}
                isActivePromotions={isActive}
                showMoreButon={false}
              />
              {instructions && (
                <PromotionHowItWorksBlock
                  steps={String(instructions)}
                  promo={mergedPromo}
                  onActivateBonus={(): void => setShowPromoPrompt(true)}
                  isActive={isActive}
                  isFreeSpinOrBet={promotionSlug.endsWith('-tag') || promotionSlug.endsWith('-fb')}
                />
              )}
            </div>
            {/* conditionally show terms only if they exist */}
            {terms && terms.trim().length > 0 && (
              <div className="promotion-main-wrapper__text-block">
                <Heading className="promotion-main-wrapper__regular" level={4}>
                  {t('promotions.terms')}
                </Heading>
                <Paragraph
                  className="promotion-main-wrapper__regular"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(terms),
                  }}
                />
              </div>
            )}
            {/* todo: this is hacky, update */}
            {!promotionSlug.endsWith('-tag') && !promotionSlug.endsWith('-fb') && (
              <RecommendedGameCarousel
                visibleConfig={{
                  wide: 6,
                  desktop: 6,
                  tablet: 4,
                  mobile: 2,
                }}
                showDots={false}
                title={t('promotions.suggested-games.title')}
                eligibleGames={eligibleGames as PromotionGames}
              />
            )}
          </>
        ) : (
          <Heading level={5} className="promotion-main-wrapper__not-found">
            {t('promotions.not-found')}
          </Heading>
        )}
      </div>
      <PromotionMessagesPrompt
        setShow={setShowPromoPrompt}
        show={showPromoPrompt}
        paragraphText={t('promotions.claim.text')}
        ctaTextSecondary={t('buttons.cancel.uppercase')}
        onClose={(): void => setShowPromoPrompt(false)}
        activatedPromo={mergedPromo}
      />
    </NavigationTemplate>
  );
};

export default PromotionMainBlock;
