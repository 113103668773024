import { formatDate, parseISO } from 'date-fns';
import React, { FunctionComponent, ReactNode } from 'react';

import './styles';
import { Pill } from '../../../shared/Pill';
import { MyAccountActivityGiftIcon } from '../../../shared/Gift';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { walletTypeNames } from '../consts';

type MyAccountActivityPanelProps = {
  /**
   * The activity title.
   */
  title?: string;

  /**
   * The stake amount.
   */
  stake?: number;
  /**

  /**
   * The activity status.
   */
  status?: string;

  /**
   * The activity amount.
   */
  amount?: number;

  /**
   * A ReactNode representing the activity icon.
   */
  ActivityIcon?: ReactNode;

  /**
   * The transaction date.
   */
  transactionDate?: string;

  /**
   * The cash balance after the transaction.
   */
  cashBalanceAfter?: number | null | undefined;

  /**
   * The isFreeBet bets
   */
  isFreeBet?: boolean;

  /**
   * Wallet type
   */
  walletType: string;
};

export const MyAccountActivityPanel: FunctionComponent<MyAccountActivityPanelProps> = ({
  title,
  stake,
  status,
  amount,
  isFreeBet,
  ActivityIcon,
  transactionDate,
  cashBalanceAfter,
  walletType,
}) => {
  const { t } = useTranslations();
  const defaultClassName = 'my-account-activity-panel';

  const walletTypeTranslation =
    walletType === walletTypeNames.main
      ? 'my-account.cash-balance'
      : walletType === walletTypeNames.locked
        ? 'my-account.locked-balance'
        : 'my-account.bonus-balance';

  return (
    <div className={defaultClassName}>
      <div className={`${defaultClassName}__activity-header`}>
        <span className={`${defaultClassName}__title`}>
          {ActivityIcon}
          {title}

          {status && (
            <Pill size="small">
              <span>{status}</span>
            </Pill>
          )}
        </span>
        <span className={`${defaultClassName}__amount`}>
          {isFreeBet && <MyAccountActivityGiftIcon />}
          &nbsp;
          {`£${Math.abs(amount || stake || 0)}`}
        </span>
      </div>

      <div className={`${defaultClassName}__activity-details`}>
        {Number.isFinite(cashBalanceAfter) && (
          <div className={`${defaultClassName}__subheading`}>
            <div className={`${defaultClassName}__balances`}>
              <span
                className={`${defaultClassName}__balances-cash`}
              >{`${t(walletTypeTranslation)} ${cashBalanceAfter}`}</span>
            </div>
          </div>
        )}

        {transactionDate && (
          <span className={`${defaultClassName}__transaction-date`}>
            {formatDate(parseISO(transactionDate), 'dd/MM/yyyy | HH:mm')}
          </span>
        )}
      </div>
    </div>
  );
};
