import React, { FC, useState } from 'react';
import { xTenantId } from '../../../../consts';
import { useSnackbar } from '../../../../hooks/snackbar';
import { useUserProfileBonusForfeitMutation } from '../../../../libs/graphql/baseAppAPI/mutations/__generated__/user-profile-bonus-forfeit.mutation.generated';
import { BonusHistoryItem } from '../../../../models/bonuses.model';
import { useUserContext } from '../../../../providers/UserProvider';
import { Button } from '../../../shared/Button';
import { PromotionMessagesPrompt } from '../PromotionMessagesPrompt';
import { ParsedPromo } from '../types';
import './PromotionCancelButton.scss';
import { useNavigate } from 'react-router-dom';

interface PromotionCancelButtonProps {
  activatedPromo: ParsedPromo | BonusHistoryItem;
  isPrimary?: boolean;
}

export const PromotionCancelButton: FC<PromotionCancelButtonProps> = ({ activatedPromo, isPrimary }): JSX.Element => {
  const { addSnack } = useSnackbar();
  const { userProfileId } = useUserContext();
  const navigate = useNavigate();
  const [userProfileBonusForfeitMutation, { loading }] = useUserProfileBonusForfeitMutation({
    variables: {
      xTenantId,
      userProfileId,
      userProfileBonusId: activatedPromo.id ? String(activatedPromo.id) : '',
    },
    refetchQueries: ['UserBonuses', 'BonusesGetAll', 'TagsObject'],
    onCompleted: () => {
      if (window.location.pathname === '/promotions') {
        // todo this is a hacky fix for the bug where the promotions ui doesn't update
        navigate(0);
      } else navigate('/promotions');
    },
    onError: () => {
      addSnack({
        type: 'error',
        theme: 'dark',
        message: 'Sorry, something went wrong. Please try again later.',
      });
    },
  });

  const [show, setShow] = useState<boolean>(false);

  const handleCancellationPrompt = (): void => {
    setShow(!show);
  };

  const onDeclineBonus = async (): Promise<void> => {
    await userProfileBonusForfeitMutation();
  };

  return (
    <>
      <Button
        className="cancel-wrapper__cancel-btn"
        type="button"
        variant={isPrimary ? 'primary' : 'secondary-alt'}
        onClick={handleCancellationPrompt}
        isSubmitting={loading}
      >
        Cancel bonus
      </Button>
      <PromotionMessagesPrompt
        type="cancellation"
        show={show}
        onClose={handleCancellationPrompt}
        imgMobilePath=""
        imgMobileAlt=""
        messageTitle="Are you sure you want to cancel?"
        ctaTextSecondary="NO THANKS"
        ctaTextPrimary="YES CANCEL"
        paragraphText="By cancelling this bonus you will lose all progress so far."
        declineBonusHandler={onDeclineBonus}
        activatedPromo={activatedPromo}
      />
    </>
  );
};
