import noop from 'lodash/noop';
import React, { FC, useState, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { xTenantId } from '../../../../consts';
import { useSignIn } from '../../../../hooks/auth';
import { useSnackbar } from '../../../../hooks/snackbar';
import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { useUserProfileBonusClaimMutation } from '../../../../libs/graphql/baseAppAPI/mutations/__generated__/apply-promo.mutation.generated';
import { BonusHistoryItem } from '../../../../models/bonuses.model';
import { useUserContext } from '../../../../providers/UserProvider';
import WelcomeBonusJourney from '../../../Account/WelcomeBonusJourney';
import { Paragraph } from '../../../shared/Paragraph';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { Prompt } from '../../../shared/Prompt';
import { PromotionPreDepositContent } from '../PromotionPreDepositJourney/PromotionPreDepositContent';
import { ParsedPromo } from '../types';
import './PromotionMessagesPrompt.scss';
import { Input } from '../../../shared/Input';
import { useDispatch } from 'react-redux';
import { setCashierModal } from '../../../../state/cashier/reducers';
import { CashierType } from '../../../../state/cashier';

export type PromotionMessagesPromptType = 'default' | 'cancellation' | 'fixed' | 'firstDeposit';

export type PromotionCancellationProps = {
  ctaTextSecondary?: string;
  ctaTextPrimary?: string;
  messageTitle?: string;
  declineBonusHandler?: () => void;
};

export type PromotionMessagesPromptProps = {
  type?: PromotionMessagesPromptType;
  /**
   * Whether to show the Modal/Drawer. Defaults to false
   */
  show: boolean;
  setShow?: (arg: boolean) => void;
  imgMobilePath?: string;
  imgMobileAlt?: string;
  paragraphText?: string;
  /**
   * Called when prompt should be hidden.
   */
  promoCode?: string;
  onClose: () => void;
  siteLink?: string;
  activatedPromo: ParsedPromo | BonusHistoryItem;
  onActivateBonus?: () => void;
  loading?: boolean;
} & PromotionCancellationProps;

export const PromotionMessagesPrompt: FC<PromotionMessagesPromptProps> = ({
  type = 'default',
  show,
  setShow,
  onClose,
  imgMobilePath,
  imgMobileAlt,
  paragraphText,
  ctaTextSecondary,
  ctaTextPrimary,
  messageTitle,
  promoCode = '',
  declineBonusHandler = noop,
  activatedPromo,
}): JSX.Element => {
  const parsedActivatedPromo = activatedPromo as ParsedPromo;
  const gameId =
    parsedActivatedPromo?.successCTAGameInformation?.gameId || parsedActivatedPromo?.successCTAGameInformation?.gameId;

  const navigate = useNavigate();
  const { userProfileId } = useUserContext();
  const { addSnack } = useSnackbar();
  const auth = useAuth();
  const signIn = useSignIn();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const isUserAuthenticated = auth?.isAuthenticated;

  const [promoStandardMutation, { loading }] = useUserProfileBonusClaimMutation({
    variables: {
      xTenantId: xTenantId,
      userProfileId,
      // TODO: Change it to pure id instead of template id
      userProfileBonusId: String(parsedActivatedPromo?.id),
    },
    refetchQueries: ['UserBonuses', 'BonusesGetAll'],
    // TODO: Replace text content by translations
    onCompleted: () => {
      setShow?.(false);
    },
    // TODO: Replace text content by translations
    onError: (error) => {
      console.error(error);
      setShow?.(false);
      addSnack({
        theme: 'dark',
        type: 'error',
        message: `There is an error, please contact technical support`,
      });
    },
  });

  const onActivateBonus = async (): Promise<void> => {
    if (isUserAuthenticated) {
      try {
        await promoStandardMutation();
      } catch (error) {
        console.error(error);
      }
    } else {
      signIn();
    }
  };

  const handlePromptBtnClick = async (): Promise<void> => {
    if (activatedPromo.dmBonusId !== 'null') {
      console.log(activatedPromo);
      dispatch(
        setCashierModal({
          isOpen: true,
          type: CashierType.Deposit,
          promoCode: activatedPromo.dmBonusId,
          promoDepositAmount: depValue,
          promoTag: activatedPromo.bonusId as string,
        })
      );
      setShow?.(false);

      return;
    }

    // condition to check 'false' is related to request response from eyapi/v1/bonus
    if (parsedActivatedPromo?.promotionCTAType === 'GameLaunch' && gameId) {
      // TODO: Get game info and launch the fame
      const gameDetails = { name: 'test name' };

      if (gameDetails) {
        navigate('test-url');

        return;
      }
    }
    // redirect to ctaURL if gameDetails don't exist

    onActivateBonus?.();
  };

  const className = 'promotion-pre-deposit';
  const drawerProps = {
    className,
  };

  const isMobile = useDeviceInfo().isMobileDevice;

  const modalProps = {
    'aria-label': 'promotion-pre-deposit-modal-aria-label',
    className,
  };

  const onPreDepositPromptClose = (): void => {
    // Run exit deposit process
    onClose();
  };

  const handleDeclineBonus = (): void => {
    declineBonusHandler();

    onClose();
  };

  const onCloseUsingCTA = (): void => {
    onClose();
  };

  const onCloseUsingBackdrop = (): void => {
    onClose();
  };

  const [depValue, setDepValue] = useState(0);
  const [inputError, setInputError] = useState('Please enter an amount');

  const onDepValChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value;

    const validNumberRegex = /^[1-9][0-9]*$/;

    if (inputValue === '' || !validNumberRegex.test(inputValue)) {
      setInputError('Please enter a valid whole number');

      return;
    }

    const value = Number(inputValue);

    setDepValue(value);

    if (activatedPromo.dmMaxAmount && value > activatedPromo.dmMaxAmount) {
      setInputError('Exceeds the maximum for this bonus');

      return;
    }

    if (activatedPromo.dmMaxAmount && value < 10) {
      setInputError('Cannot be lower than minimum deposit amount');

      return;
    }

    setInputError('');
  };

  switch (type) {
    case 'default':
      return (
        <Prompt
          elementToFocusOnOpen={inputRef}
          className="prompt--default"
          show={show}
          title={parsedActivatedPromo?.title || ''}
          onClose={onClose}
          image={{
            src: isMobile && imgMobilePath ? imgMobilePath : parsedActivatedPromo?.imgPath,
            alt: (isMobile && imgMobileAlt) || parsedActivatedPromo?.imgAlt,
          }}
          isSubmitting={loading}
          PrimaryButton={{
            onClick: handlePromptBtnClick,
            text: parsedActivatedPromo?.promoButtonText,
            disabled: inputError.length > 0,
          }}
          SecondaryButton={
            ctaTextSecondary
              ? {
                  onClick: onCloseUsingCTA,
                  text: ctaTextSecondary,
                }
              : undefined
          }
        >
          <Paragraph noMargin size="sm">
            {'How much would you like to deposit?'}
          </Paragraph>
          <Paragraph className="paragraph--strong" size="sm">{`£10 - £${activatedPromo.dmMaxAmount}`}</Paragraph>
          <Input
            ref={inputRef}
            validationMessages={[{ id: 'id', message: inputError }]}
            validationState={inputError.length ? 'error' : 'success'}
            type="number"
            theme="dark"
            onChange={onDepValChange}
            onBlur={onDepValChange}
            label=""
            step={1}
            inputMode="numeric"
          />
        </Prompt>
      );
    case 'cancellation':
      return (
        <Prompt
          className="prompt--cancellation"
          show={show}
          title={messageTitle || parsedActivatedPromo?.title}
          onClose={onCloseUsingBackdrop}
          image={{
            src: isMobile && imgMobilePath ? imgMobilePath : parsedActivatedPromo?.imgPath,
            alt: parsedActivatedPromo?.title,
          }}
          PrimaryButton={{
            onClick: handleDeclineBonus,
            text: ctaTextPrimary || parsedActivatedPromo?.promoButtonText,
          }}
          SecondaryButton={
            ctaTextSecondary
              ? {
                  onClick: onCloseUsingCTA,
                  text: ctaTextSecondary,
                }
              : undefined
          }
        >
          <Paragraph noMargin size="sm">
            {paragraphText}
          </Paragraph>
        </Prompt>
      );
    case 'fixed':
      return (
        <PopUpHandler isOpen={show} onClose={onPreDepositPromptClose} modalProps={modalProps} drawerProps={drawerProps}>
          <PromotionPreDepositContent onClose={onClose} activatedPromo={parsedActivatedPromo} />
        </PopUpHandler>
      );
    case 'firstDeposit':
      return (
        <WelcomeBonusJourney
          isShown={show}
          onClose={onClose}
          isDepositJourney
          promoCode={promoCode}
          termsAndConditions={parsedActivatedPromo?.description}
          imgAlt={isMobile ? imgMobileAlt : parsedActivatedPromo?.imgAlt}
          imgPath={isMobile ? imgMobilePath : parsedActivatedPromo?.imgPath}
        />
      );
  }
};
