import React, { FunctionComponent } from 'react';
import { AccountTile } from '../../AccountTile';
import { BonusIcon, CashIcon } from '../../../../shared/SVG/Icons';
import { useUserBalance } from '../../../../../hooks/useUserBalance';
import { AccountWalletTextContent } from './AccountWalletTextContent';
import { useHorizontalDrag } from '../../../../../hooks/presentation-logic';
import './AccountWallet.scss';

export const AccountWallet: FunctionComponent = () => {
  const { isBalanceLoading, userBalance } = useUserBalance();

  const {
    dragContainerAttributes,

    dragItemAttributes,
  } = useHorizontalDrag();

  return (
    <div className="account-wallet" {...dragContainerAttributes}>
      <div {...dragItemAttributes} className="account-wallet__wrapper">
        <AccountTile withBoxShadow className="account-wallet__tile">
          <AccountWalletTextContent
            balanceType="cash"
            isLoading={isBalanceLoading}
            balance={userBalance.mainCashBalance + userBalance.lockedCashBalance}
          />
          <div className="account-wallet__tile-icon">
            <CashIcon />
          </div>
        </AccountTile>
        <AccountTile withBoxShadow className="account-wallet__tile">
          <AccountWalletTextContent
            balanceType="bonus"
            isLoading={isBalanceLoading}
            balance={userBalance.bonusBalance}
          />
          <div className="account-wallet__tile-icon">
            <BonusIcon />
          </div>
        </AccountTile>
      </div>
    </div>
  );
};
