import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BonusGetBySlugQueryVariables = Types.Exact<{
  language: Types.Scalars['String']['input'];
  slug: Types.Scalars['String']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type BonusGetBySlugQuery = { __typename?: 'Query', bonus: Array<{ __typename?: 'bonus', template_id?: string | null, dm_template_id?: string | null, dm_max_amount?: number | null, start_date?: any | null, end_date?: any | null, translations?: Array<{ __typename?: 'bonus_translations', title?: string | null, subtitle?: string | null, description?: string | null, terms_and_conditions?: string | null, instructions?: string | null, thumbnail?: { __typename?: 'directus_files', filename_disk?: string | null, title?: string | null, width?: number | null, height?: number | null } | null } | null> | null, eligible_games?: Array<{ __typename?: 'bonus_game', game_id?: { __typename?: 'game', game_id?: string | null, provider?: { __typename?: 'game_provider', mediator_id?: string | null } | null, translations?: Array<{ __typename?: 'game_translations', display_name?: string | null, slug?: string | null } | null> | null, game_image?: { __typename?: 'directus_files', filename_disk?: string | null, width?: number | null, height?: number | null } | null, tags?: Array<{ __typename?: 'game_game_tags', game_tags_id?: { __typename?: 'game_tags', translations?: Array<{ __typename?: 'game_tags_translations', name?: string | null } | null> | null } | null } | null> | null } | null } | null> | null }> };


export const BonusGetBySlugDocument = gql`
    query BonusGetBySlug($language: String!, $slug: String!, $limit: Int) {
  bonus(
    filter: {status: {_eq: "published"}, translations: {languages_language_code: {language_code: {_eq: $language}}, slug: {_eq: $slug}}}
  ) {
    template_id
    dm_template_id
    dm_max_amount
    start_date
    end_date
    translations(
      filter: {languages_language_code: {language_code: {_eq: $language}}}
    ) {
      title
      subtitle
      description
      terms_and_conditions
      instructions
      thumbnail(filter: null) {
        filename_disk
        title
        width
        height
      }
    }
    eligible_games(
      filter: {game_id: {status: {_eq: "published"}, provider: {status: {_eq: "published"}}}}
      limit: $limit
    ) {
      game_id(filter: null) {
        game_id
        provider(filter: null) {
          mediator_id
        }
        translations(
          filter: {languages_language_code: {language_code: {_eq: $language}}}
        ) {
          display_name
          slug
        }
        game_image(filter: null) {
          filename_disk
          width
          height
        }
        tags {
          game_tags_id(filter: null) {
            translations(
              filter: {languages_language_code: {language_code: {_eq: $language}}}
            ) {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBonusGetBySlugQuery__
 *
 * To run a query within a React component, call `useBonusGetBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonusGetBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonusGetBySlugQuery({
 *   variables: {
 *      language: // value for 'language'
 *      slug: // value for 'slug'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBonusGetBySlugQuery(baseOptions: Apollo.QueryHookOptions<BonusGetBySlugQuery, BonusGetBySlugQueryVariables> & ({ variables: BonusGetBySlugQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>(BonusGetBySlugDocument, options);
      }
export function useBonusGetBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>(BonusGetBySlugDocument, options);
        }
export function useBonusGetBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>(BonusGetBySlugDocument, options);
        }
export type BonusGetBySlugQueryHookResult = ReturnType<typeof useBonusGetBySlugQuery>;
export type BonusGetBySlugLazyQueryHookResult = ReturnType<typeof useBonusGetBySlugLazyQuery>;
export type BonusGetBySlugSuspenseQueryHookResult = ReturnType<typeof useBonusGetBySlugSuspenseQuery>;
export type BonusGetBySlugQueryResult = Apollo.QueryResult<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>;