import { FC, useEffect, useState } from 'react';
import { useUserContext } from '../../providers/UserProvider';
import { seonService, initSeon } from '../Account/seon/seon-service';
import { useTagsObjectQuery } from '../../libs/graphql/baseAppAPI/queries/__generated__/tags-get.query.generated';
import { xTenantId } from '../../consts';

export const SeonCheck: FC = () => {
  const { userProfileId, user } = useUserContext();
  const [seonServiceExecuted, setSeonServiceExecuted] = useState(false);
  const { data } = useTagsObjectQuery({
    variables: {
      xTenantId,
      externalResourceId: userProfileId,
      externalResource: 'UserProfileId',
    },
  });

  const hasSeonCheckedTag = (): boolean => {
    if (!data || !data.tagsObject || !Array.isArray(data.tagsObject)) {
      return false;
    }

    return data.tagsObject.some((tagObj) => tagObj.tag?.name === 'SEONChecked');
  };

  useEffect(() => {
    localStorage.removeItem('seon_initialized');
    const initializeSeon = async (): Promise<void> => {
      try {
        await initSeon();
        // initSeon should set the localStorage item internally
      } catch (error) {
        console.error('Error initializing Seon:', error);
      }
    };

    initializeSeon();
  }, []);

  useEffect(() => {
    const runSeonService = async (): Promise<void> => {
      try {
        // Only proceed if:
        // 1. Seon is initialized
        // 2. We have all required data
        // 3. User does NOT have the SEONChecked tag
        // 4. The service hasn't been executed yet
        if (
          !seonServiceExecuted &&
          localStorage.getItem('seon_initialized') &&
          userProfileId &&
          user &&
          data &&
          !hasSeonCheckedTag()
        ) {
          // Set flag before execution to prevent concurrent runs
          setSeonServiceExecuted(true);

          await seonService(user, userProfileId, 'account_login');
          console.log('Seon service completed');
        } else if (hasSeonCheckedTag()) {
          // Also set the flag if the user already has the tag
          setSeonServiceExecuted(true);
          console.log('SEON check already performed for this user');
        }
      } catch (error) {
        console.error('Error running Seon service:', error);
        // Don't reset the flag on error - we still attempted execution
      }
    };

    runSeonService();
  }, [userProfileId, user, data, seonServiceExecuted]);

  return null;
};
