import React, { FunctionComponent, ReactNode, useEffect, useRef, useState, useCallback } from 'react';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { Prompt } from '../../../shared/Prompt';
import { Trans } from '../../../shared/Trans';
import { NotificationMessage } from '../../../shared/NotificationMessage';
import './Cashier.scss';
import { useDispatch, useSelector } from 'react-redux';
import { cashierModal, CashierType } from '../../../../state/cashier';
import { setCashierModal } from '../../../../state/cashier/reducers';

import _PaymentIQCashier, { IPiqCashierApiMethods, IPiqCashierConfig } from 'paymentiq-cashier-bootstrapper';
import { useUserContext } from '../../../../providers/UserProvider';
import { useSnackbar } from '../../../../hooks/snackbar';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { useNavigate } from 'react-router-dom';
import { fetchSessionId, assignBonus, removeTag } from './service';
import { useAuth } from 'react-oidc-context';
import useDestroyUserSession from '../../../../hooks/auth/useDestroyUserSession';
import { trackEvent } from '../../../../tracking/tracking';
import { DepositEvent } from '../../../../tracking/types';
import { APP_URLS, locale } from '../../../../consts';
import { Paragraph } from '../../../shared/Paragraph';
import { Link } from '../../../shared/Link';
import { NavGiftIcon } from '../../../shared/SVG/Icons';
import { AccountTile } from '../AccountTile';
import { Heading } from '../../../shared/Heading';

type TransactionStatus = 'success' | 'failure' | 'pending' | 'cancelled' | null;
const POF_ACKNOWLEDGE_CHECK_KEY = 'pof-acknowledged';

export const Cashier: FunctionComponent = () => {
  const { t } = useTranslations();
  const auth = useAuth();
  const { logoutUser } = useDestroyUserSession();
  const cashierModalStore = useSelector(cashierModal);
  const dispatch = useDispatch();
  const { type, isOpen, promoCode, promoDepositAmount, promoTag } = cashierModalStore;
  const cashierRef = useRef<HTMLDivElement>(null);
  const { userProfileId, user } = useUserContext();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(null);
  const { addSnack } = useSnackbar();
  const [isContainerReady, setIsContainerReady] = useState(false);
  const deposit = type === CashierType.Deposit;
  const withdrawal = type === CashierType.Withdrawal;
  const cashierType = deposit ? CashierType.Deposit : withdrawal ? CashierType.Withdrawal : undefined;
  const navigate = useNavigate();
  const [showPoF, setShowPoF] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen && !showPoF) {
      (async (): Promise<void> => {
        auth.user?.expired &&
          (await auth.signinSilent().catch((error) => {
            console.error(error);
            logoutUser();
          }));

        !sessionId && getSessionId().then((id) => setSessionId(id));
      })();
    }
  }, [isOpen, sessionId, showPoF]);

  useEffect(() => {
    if (isOpen && deposit) {
      const hasAcknowledged = localStorage.getItem(POF_ACKNOWLEDGE_CHECK_KEY) === 'true';

      setShowPoF(!hasAcknowledged);
    } else setShowPoF(false);
  }, [isOpen, deposit]);

  const checkContainerReady = useCallback(() => {
    if (isOpen && cashierRef.current) {
      setIsContainerReady(true);
    } else {
      setIsContainerReady(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const timeoutId = setTimeout(checkContainerReady, 500);

    return () => clearTimeout(timeoutId);
  }, [isOpen, showPoF, checkContainerReady]);

  useEffect(() => {
    if (isOpen && sessionId && isContainerReady && !transactionStatus && !showPoF) {
      initializeCashier(sessionId);
    }
  }, [isOpen, sessionId, isContainerReady, transactionStatus, showPoF]);

  // New effect to listen for gcv2DepositEvent
  useEffect(() => {
    const handleDepositEvent = (): void => {
      dispatch(setCashierModal({ isOpen: true, type: CashierType.Deposit }));
    };

    window.addEventListener('gcv2DepositEvent', handleDepositEvent);

    return () => {
      window.removeEventListener('gcv2DepositEvent', handleDepositEvent);
    };
  }, [dispatch]);

  const getSessionId = async (): Promise<string | null> => {
    try {
      return await fetchSessionId(userProfileId, withdrawal ? 'withdraw' : cashierType);
    } catch (error) {
      addSnack({
        type: 'error',
        message: t('errors.general'),
        theme: 'dark',
      });
      handleClose();

      return null;
    }
  };

  const handlePromoSuccess = async (): Promise<void> => {
    assignBonus(userProfileId, promoCode || '', promoDepositAmount || 0);
    removeTag(userProfileId, promoTag || '');
    // temp solution, todo update this
    navigate('/game-lobby/slots');
  };

  const initializeCashier = (sessionId: string): void => {
    // prefill and disable editing of amount if the cashier has been launched with promo
    const extraConfig = promoCode
      ? {
          amount: promoDepositAmount,
          showAmount: false,
        }
      : {};

    try {
      new _PaymentIQCashier(
        '#cashier-container',
        {
          fetchConfig: true,
          defaultLoader: true,
          merchantId: process.env.REACT_APP_PAYMENTIQ_MERCHANT_ID as string,
          userId: userProfileId,
          sessionId: sessionId,
          environment:
            process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' || process.env.REACT_APP_ENVIRONMENT === 'PREPROD'
              ? 'production'
              : 'test',
          method: cashierType as IPiqCashierConfig['method'],
          containerHeight: 'auto',
          locale,
          ...extraConfig,
        },
        (api: IPiqCashierApiMethods) => {
          api.on({
            // Existing implementations
            cashierInitLoad: () => console.log('[Cashier] cashierInitLoad'),
            success: (data) => {
              console.log('[Cashier] success', data);
              setTransactionStatus('success');

              promoCode && handlePromoSuccess();

              cashierType === 'deposit' &&
                trackEvent<DepositEvent>({
                  event: 'depositconfirm',
                  value: data.data.payload.txAmount,
                  currency: data.data.payload.txAmountCy,
                  playerId: userProfileId,
                  playerEmail: String(user?.email),
                  playerPhone: String(user?.mobileNumber?.prefix) + user?.mobileNumber?.number,
                });
            },
            failure: (data) => {
              console.log('[Cashier] failure', data);
              setTransactionStatus('failure');
            },
            cancelled: (data) => {
              console.log('[Cashier] cancelled', data);
              setTransactionStatus('cancelled');
            },
            pending: (data) => {
              console.log('[Cashier] pending', data);
              setTransactionStatus('pending');
            },

            // New implementations
            lookupInitLoad: () => {
              console.log('[Cashier] lookupInitLoad');
            },
            change: (data) => {
              console.log('[Cashier] change', data);
            },
            pause: (data) => {
              console.log('[Cashier] pause', data);
            },
            resume: (data) => {
              console.log('[Cashier] resume', data);
            },
            isVisible: () => {
              console.log('[Cashier] isVisible');
            },
            isHidden: () => {
              console.log('[Cashier] isHidden');
            },
            update: (data) => {
              console.log('[Cashier] update', data);
            },
            unresolved: (data) => {
              console.log('[Cashier] unresolved', data);
            },
            isLoading: (data) => {
              console.log('[Cashier] isLoading', data);
            },
            doneLoading: (data) => {
              console.log('[Cashier] doneLoading', data);
            },
            newProviderWindow: (data) => {
              console.log('[Cashier] newProviderWindow', data);
            },
            paymentMethodSelect: (data) => {
              console.log('[Cashier] paymentMethodSelect', data);
            },
            paymentMethodPageEntered: (data) => {
              console.log('[Cashier] paymentMethodPageEntered', data);
            },
            navigate: (data) => {
              console.log('[Cashier] navigate', data);
            },
            cancelledPendingWD: (data) => {
              console.log('[Cashier] cancelledPendingWD', data);
            },
            validationFailed: (data) => {
              console.log('[Cashier] validationFailed', data);
            },
            onLoadError: (data) => {
              console.log('[Cashier] onLoadError', data);
            },
            lookupUserIdentified: (data) => {
              console.log('[Cashier] lookupUserIdentified', data);
            },
            transactionInit: (data) => {
              console.log('[Cashier] transactionInit', data);
            },
            lookupUserData: (data) => {
              console.log('[Cashier] lookupUserData', data);
            },
          });
        }
      );
    } catch (error) {
      console.error('Error initializing cashier:', error);
      setTransactionStatus('failure');
    }
  };

  const handleClose = (): void => {
    dispatch(setCashierModal({ isOpen: false, promoCode: undefined }));
    setSessionId(null);
    setTransactionStatus(null);
    setIsContainerReady(false);
  };

  const handleRetry = (): void => {
    setTransactionStatus(null);
    setSessionId(null);
  };

  const renderContent = (): ReactNode => {
    if (!transactionStatus) {
      return <div id="cashier-container" className="cashier__content" ref={cashierRef}></div>;
    }

    switch (transactionStatus) {
      case 'success':
        return (
          // if this is a div, the cashier seems to hijack it even without the id
          <section className="success-wrapper">
            <NotificationMessage
              iconVariant="Success"
              className="cashier__success-message"
              text={t(`cashier.success-${cashierType}`)}
              buttonText={t('cashier.closeButton')}
              onClick={handleClose}
            />
            {promoCode && (
              <AccountTile className="promo-details">
                <section className="success-heading">
                  <NavGiftIcon active />
                  <Heading level={4}>Bonus awarded!</Heading>
                </section>
                <Paragraph size="sm">{`£${promoDepositAmount} has been deposited to your cash account and you have received £${promoDepositAmount} in bonus funds`}</Paragraph>
              </AccountTile>
            )}
          </section>
        );
      case 'failure':
        return (
          <NotificationMessage
            iconVariant="Error"
            className="cashier__error-message"
            text={t(`cashier.failed-${cashierType}`)}
            buttonText={t('cashier.tryAgainButton')}
            onClick={handleRetry}
          />
        );
      case 'pending':
        return (
          <NotificationMessage
            iconVariant="Info"
            className="cashier__info-message"
            text={t(`cashier.pending-${cashierType}`)}
            buttonText={t('cashier.closeButton')}
            onClick={handleClose}
          />
        );
      case 'cancelled':
        return (
          <NotificationMessage
            iconVariant="Warning"
            text={t(`cashier.cancelled-${cashierType}`)}
            className="cashier__error-message"
            buttonText={t('cashier.tryAgainButton')}
            onClick={handleRetry}
          />
        );
      default:
        return null;
    }
  };

  const onClickPOFPolicyLink = (): void => {
    acknowledgePoF();
    navigate('/pages/pof-policy');
    dispatch(setCashierModal({ isOpen: false }));
  };

  const onClickDepositLimitsButton = (): void => {
    acknowledgePoF();
    navigate(APP_URLS.myAccount.gamblingControls);
    dispatch(setCashierModal({ isOpen: false }));
  };

  const onClickContinueDepositButton = (): void => {
    acknowledgePoF();
  };

  const acknowledgePoF = (): void => {
    localStorage.setItem(POF_ACKNOWLEDGE_CHECK_KEY, 'true');
    setShowPoF(false);
  };

  return (
    <>
      {isOpen && deposit && showPoF && (
        <Prompt
          show={true}
          PrimaryButton={{ text: t('ftd.prompt.primaryCTA'), onClick: onClickContinueDepositButton }}
          SecondaryButton={{ text: t('ftd.prompt.secondaryCTA'), onClick: onClickDepositLimitsButton }}
          title={t('ftd.prompt.heading')}
          className="pof-prompt"
          cannotClose={true}
        >
          <Paragraph>{t('ftd.prompt.content.intro')}</Paragraph>
          <Paragraph></Paragraph>
          <Paragraph className="pof-li">
            <Trans keyProp={'ftd.prompt.content.li1'}>
              <Link onClick={onClickPOFPolicyLink}></Link>
            </Trans>
          </Paragraph>
          <Paragraph className="pof-li">{t('ftd.prompt.content.li2')}</Paragraph>
        </Prompt>
      )}

      {isOpen && !showPoF && (
        <PopUpHandler
          isOpen
          header={t(`cashier.${cashierType}.heading`)}
          onClose={handleClose}
          modalProps={{
            'aria-label': type,
            className: 'cashier__modal',
          }}
          drawerProps={{
            'aria-label': type,
            className: 'cashier__drawer',
          }}
        >
          {renderContent()}
        </PopUpHandler>
      )}
    </>
  );
};
