import React, { FC, useRef } from 'react';
import GameMenuTile from './GameMenuTile';
import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { Paragraph } from '../../../shared/Paragraph';
import { Tooltip } from '../../../shared/Tooltip';
import { DepositButton } from '../../../shared/DepositButton';
import { BonusIcon, CashIcon } from '../../../shared/SVG/Icons';
import { RewardItem, RewardItemProps } from '../../../Header/HeaderBalance/HeaderBalanceDetails/HeaderBalanceReward';
import { BonusItem } from '../../../Header/HeaderBalance/HeaderBalanceDetails/HeaderBalanceBonus';
import { useDispatch } from 'react-redux';
import { setCashierModal } from '../../../../state/cashier/reducers';
import { CashierType } from '../../../../state/cashier';
import { useFetchMergedPromotions } from '../../../../hooks/useFetchMergedPromotions';
import { PromotionTabsType } from '../../../Layout/Promotions/PromotionsList/types';
import { ParsedPromo } from '../../../Layout/Promotions/types';
import { useUserBalance } from '../../../../hooks/useUserBalance';
import { Balance } from '../../../shared/Balance';
import './styles';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { Trans } from '../../../shared/Trans';

type GameMenuBalanceProps = {
  /**
   * Callback that's executed when the account is requested to close
   */
  closeMenu?: () => void;
  /**
   * Callback that's executed when the account is requested to open
   */
  openDeposit?: () => void;
  showBonusDetails?: boolean;
};

const GameMenuBalance: FC<GameMenuBalanceProps> = ({ showBonusDetails = true }): JSX.Element => {
  const { t } = useTranslations();
  const { isBalanceLoading, userBalance } = useUserBalance();

  const isMobile = useDeviceInfo().isMobileDevice;
  const dispatch = useDispatch();

  const { mergedFilteredPromotions, isPromosLoading } = useFetchMergedPromotions(PromotionTabsType.ACTIVE);

  const scrollRef = useRef<HTMLDivElement>(null);
  const expandedItems = useRef<number>(0);

  // TODO: Replace the mock with actual data
  const rewards: Array<RewardItemProps> = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onItemExpand = (state: boolean): void => {
    expandedItems.current += state ? 1 : -1;
    const showState = expandedItems.current > 0;

    if (scrollRef && scrollRef.current && !showState) {
      scrollRef.current.scrollTo(0, 0);
    }
  };

  const onDepositBtnClick = (): void => {
    dispatch(setCashierModal({ isOpen: true, type: CashierType.Deposit }));
  };

  const onShowCashInfo = (): void => {
    console.log('onShowCashInfo');
  };
  const onShowBonusInfo = (): void => {
    console.log('onShowBonusInfo');
  };

  return (
    <div className="game-menu-balance">
      <div className="game-menu-balance__cash">
        <GameMenuTile>
          <>
            <div className="game-menu-balance__content-wrap">
              <CashIcon />
              <div className="game-menu-balance__content">
                <div className="game-menu-balance__text-tooltip-wrap">
                  <Paragraph size="xs" highlight className="game-menu-balance__balance-type">
                    {t('balance.cash-balance')}
                  </Paragraph>
                  <Tooltip
                    position="bottom"
                    buttonProps={{ 'aria-label': 'Info icon label' }}
                    onShow={onShowCashInfo}
                    entryAnimation={isMobile ? 'slideFromRight' : 'none'}
                  >
                    {t('balance.tooltip')}
                  </Tooltip>
                </div>
                <Balance
                  isHeading
                  isLoading={isBalanceLoading}
                  balance={userBalance.mainCashBalance + userBalance.lockedCashBalance}
                  className="game-menu-balance__balance"
                />
              </div>
            </div>
            <DepositButton className="game-menu-balance__button" onClick={onDepositBtnClick} />
          </>
        </GameMenuTile>
      </div>
      <div className="game-menu-balance__cash">
        <GameMenuTile>
          <div className="game-menu-balance__flex-wrap">
            <div className="game-menu-balance__content-wrap">
              <BonusIcon />
              <div className="game-menu-balance__content">
                <div className="game-menu-balance__text-tooltip-wrap">
                  <Paragraph size="xs" highlight className="game-menu-balance__balance-type">
                    <Trans keyProp="balance.total-bonus-trans">
                      Total <span>Bonus Balance</span>
                    </Trans>
                  </Paragraph>
                  <Tooltip
                    position="bottom"
                    buttonProps={{ 'aria-label': 'Info icon label' }}
                    onShow={onShowBonusInfo}
                    entryAnimation={isMobile ? 'slideFromRight' : 'none'}
                  >
                    {t('balance.total-bonus')}
                  </Tooltip>
                </div>
                <Balance
                  isHeading
                  isLoading={isBalanceLoading}
                  balance={userBalance.bonusBalance}
                  className="game-menu-balance__balance"
                />
              </div>
            </div>
            {!!(rewards.length || mergedFilteredPromotions.length) && showBonusDetails && (
              <div className="balance-details__card-body">
                {(rewards as RewardItemProps[]).map((props, index) => (
                  <RewardItem {...props} key={`balance-reward-item-${index}`} onExpand={onItemExpand} />
                ))}

                {mergedFilteredPromotions &&
                  (mergedFilteredPromotions as unknown as ParsedPromo[])
                    .filter((promotion) => promotion.balanceAmount !== undefined)
                    .map((promotion, index) => (
                      <BonusItem
                        promotion={promotion}
                        key={`balance-bonus-item-${index}`}
                        isFetching={isPromosLoading}
                        index={index}
                      />
                    ))}
              </div>
            )}
          </div>
        </GameMenuTile>
      </div>
    </div>
  );
};

export default GameMenuBalance;
