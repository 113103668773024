import axios from 'axios';
import { getToken } from '../../../../utils/auth';
import { xTenantId, currencyCode } from '../../../../consts';
import { CashierType } from '../../../../state/cashier';

const requestUrl = process.env.REACT_APP_BASE_AUTH_URL;
const bonusUrl = process.env.REACT_APP_BONUS_WEBHOOK_URL;
const tagUrl = process.env.REACT_APP_TAG_REMOVAL_WEBHOOK;

export const fetchSessionId = async (
  userProfileId: string,
  method: string | CashierType | undefined
): Promise<string> => {
  const token = getToken();
  const response = await axios.post(
    `${requestUrl}/endeavour-devcode/v1/user-profiles/${userProfileId}/launch/${method}`,
    { currencyCode },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Tenant-ID': xTenantId,
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data.sessionContextId;
};

export const assignBonus = async (
  userProfileId: string,
  bonusId: string,
  contributionAmount: number
): Promise<boolean> => {
  try {
    await axios.post(bonusUrl as string, {
      UserId: userProfileId,
      RewardId: bonusId,
      RewardAmount: contributionAmount.toString(),
      RewardType: 'Bonus',
    });

    return true;
  } catch (error) {
    console.log(error);

    return false;
  }
};

export const removeTag = async (userProfileId: string, tagId: string): Promise<boolean> => {
  try {
    await axios.post(tagUrl as string, {
      UserId: userProfileId,
      TagId: tagId,
    });

    return true;
  } catch (error) {
    console.log(error);

    return false;
  }
};
