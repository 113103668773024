import { PaginationParams } from './api.model';

export interface CustomField {
  key: string;
  valueType: string;
  value: string;
}

export interface BonusHistoryItem {
  id: number;
  bonusId: number;
  dmBonusId: string;
  dmMaxAmount: number;
  pageUrl: string;
  displayName: string;
  bonusAmount: number;
  selfWageringRequirementsTarget: number;
  wageringRequirementsTarget: number;
  currentContributions: number;
  activedDate: string;
  expiryDate: Nullable<string>;
  revokeDate: Nullable<string>;
  targetWalletType: string;
  targetWalletVertical: string;
  status: string;
  promoCode: string;
  currentAccumulation: Nullable<number>;
  targetAccumulation: Nullable<number>;
  bonusType: string;
  payoutAfterPeriod: boolean;
  enhancedPayoutQualifyingStakesSum: number;
  endDateToQualify: Nullable<string>;
  termsAndConditions: Nullable<string>;
  reason: Nullable<string>;
  reasonDetails: Nullable<string>;
  customFields?: CustomField[];
  configuration: {
    MaximumBonusAmount?: Nullable<number>;
    BonusPercentage?: Nullable<number>;
  } | null;
  buyInAmount: number | null;
  description: Nullable<string>;
  detailedDescription: Nullable<string>;
  maximumStakeRestriction: Nullable<number>;
  images: {
    default: {
      url: Nullable<string>;
      alt: Nullable<string>;
    };
  };
}

export enum BonusStatusType {
  PartQualified = 'PartQualified',
  Active = 'Active',
  Fulfilled = 'Fulfilled',
  Revoked = 'Revoked',
  Cancelled = 'Cancelled',
  Declined = 'Declined',
  Inherited = 'Inherited',
  Completed = 'Completed',
  Expired = 'Expired',
  NotQualified = 'NotQualified',
  OptedOut = 'OptedOut',
  Undeclined = 'Undeclined',
  FreeSpinsRewarded = 'FreeSpinsRewarded',
  SportsbookRewarded = 'SportsbookRewarded',
  Pending = 'Pending',

  Rewarded = 'Rewarded',
  PromotionRewarded = 'PromotionRewarded',
}

export interface GetBonusHistoryParams extends PaginationParams {
  promoName?: string;
  promoStatuses?: string;
  bonusTypes?: string;
  activatedDateStart?: string;
  activatedDateEnd?: string;
  bonusId?: string;
}

export enum BonusType {
  FirstDeposit = 'FirstDeposit',
  Deposit = 'Deposit',
  Instant = 'Instant',
  PlayerRegistration = 'PlayerRegistration',
  CardRegistration = 'CardRegistration',
  DepositOverPeriod = 'DepositOverPeriod',
  CashbackOnTotalStake = 'CashbackOnTotalStake',
  CashbackOnNetLosses = 'CashbackOnNetLosses',
  OptIn = 'OptIn',
  Content = 'Content',
  BuyIn = 'BuyIn',
}

export enum TargetWalletType {
  Cash = 'Cash',
  Bonus = 'Bonus',
  Games = 'Games',
  GameTag = 'GameTag',
  None = 'None',
  FreeSpins = 'FreeSpins',
  Sportsbook = 'Sportsbook',
  RewardCash = 'RewardCash',
  ExternalPromotion = 'ExternalPromotion',
}
export interface GetAvailableBonusesParams extends PaginationParams {
  type?: BonusType[];
  targetWalletType?: TargetWalletType[];
  includeUnclaimable?: boolean;
  includeDefaultBonuses?: boolean;
}

export interface GetPlayerBonusParams {
  depositAmount?: number;
  buyInAmount?: number;
}

export interface GetBonusHistoryParams extends PaginationParams {
  promoName?: string;
  promoStatuses?: string;
  bonusTypes?: string;
  activatedDateStart?: string;
  activatedDateEnd?: string;
  bonusId?: string;
}

export interface BonusImage {
  default: {
    url: string;
    alt: string;
  };
}

export interface BonusItem {
  displayName: string;
  description: string;
  detailedDescription: Nullable<string>;
  termsAndConditions: Nullable<string>;
  canBeClaimed: boolean;
  priority: number;
  publishDate: string;
  unpublishDate: string;
  reasonId: number;
  reason: string;
  internalName: string;
  id: number;
  startDate: string;
  endDate: string;
  promoCode: string;
  bonusAmount: number;
  configuration: {
    MaximumBonusAmount?: number;
    BonusPercentage?: number;
    MinimumDeposit?: string;
    IsPercentage?: boolean;
  } | null;
  bonusType: string;
  targetWalletType: string;
  targetWalletVertical: string;
  previousBonusType: string;
  isVisibleToLoggedOut: boolean;
  hidden: boolean;
  inheritanceChain: Nullable<string>;
  activatedCount: number;
  maximumActivationLimit: Nullable<number>;
  totalAmountGiven: number;
  totalAmountLimit: Nullable<number>;
  images: {
    default: {
      url: Nullable<string>;
      alt: Nullable<string>;
    };
  };
  default: boolean;
  promotionTitle: string;
  shortDescription: string;
}

export interface ContentDataProps {
  title?: string;
  expireDateLabel?: string;
  failedCTAText?: string;
  failedParagraph?: string;
  failedTitle?: string;
  imgAlt?: string;
  isFailedWithoutRedirect?: string;
  isSuccessWithoutRedirect?: string;
  longDescription?: string;
  pageUrl?: string;
  promoButtonText?: string;
  promotionCTAType?: string;
  promotionTitle?: string;
  shortDescription?: string;
  shortDescriptionTC?: string;
  stepsTitle?: string;
  successCTAType?: string;
  successCTAText?: string;
  successParagraph?: string;
  successTitle?: string;
  infoText?: string;
  status?: string;
  imgPath?: string;
  failedCTAURL?: string;
  successCTAURL?: string;
  descriptionTC?: string;
  successCTAGameInformation?: {
    gameId: string;
    gameEnabled: boolean;
  };
}

export interface CancelDeclineBonusParams {
  bonusIdentifier: string;
  action: 'cancel' | 'decline';
  targetWalletType?: string;
}

export interface CustomField {
  key: string;
  valueType: string;
  value: string;
}

export type MergedBonusItem = BonusItem & ContentDataProps;
