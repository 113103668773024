import { PopUpHandler } from '../../../shared/PopupHandler';
import { FC, useState, useEffect, useRef, ReactNode } from 'react';
import { fetchUploaderIframeURL } from './service';
import { useUserContext } from '../../../../providers/UserProvider';
import { Button } from '../../../shared/Button';
import { LogoSpinner } from '../../../shared/LogoSpinner/LogoSpinner';
import { Prompt } from '../../../shared/Prompt';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import {
  useTagsObjectQuery,
  TagsObjectQuery,
} from '../../../../libs/graphql/baseAppAPI/queries/__generated__/tags-get.query.generated';
import './DocumentUploader.scss';
import { xTenantId } from '../../../../consts';

type VerificationStep = 'id' | 'address' | 'complete';
type UploadStatus = 'initial' | 'loading' | 'success' | 'failed';

const UPLOAD_COMPLETED_KEY = 'doc_upload_completed';

declare global {
  interface Window {
    AC9124: new () => {
      init: (config: AddressVerificationConfig) => void;
    };
  }
}

interface AddressVerificationConfig {
  merchantkey: string;
  name: string;
  surname: string;
  middlename?: string;
  street: string;
  city: string;
  postcode: string;
  country: string;
  countrycode: string;
  locale: string;
  reference: string;
  onInit: (data: never) => void;
  onComplete: (data: never) => void;
  onFail: (data: never) => void;
}

export const DocumentUploader: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('initial');
  const [currentStep, setCurrentStep] = useState<VerificationStep>('id');
  const [addressVerificationInitialized, setAddressVerificationInitialized] = useState(false);
  const [isAddressVerificationLoading, setIsAddressVerificationLoading] = useState(true);

  const { userProfileId, compliance, user } = useUserContext();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const addressVerificationRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslations();
  const isDocUploaderEnabled = JSON.parse(process.env.REACT_APP_ENABLE_DOCUPLOAD as string);

  useEffect(() => {
    const handleMessage = (event: MessageEvent): void => {
      if (!event.origin.includes('agechecked.sb.getid.dev') || !('getidEventName' in event.data)) {
        return;
      }

      if (event.data.getidEventName === 'complete') {
        console.log('[DocUpload]', 'ID verification completed successfully', event);
        setUploadStatus('success');
        // Move to address verification step
        setCurrentStep('address');
      }

      if (event.data.getidEventName === 'fail') {
        console.log('[DocUpload]', 'ID verification failed', event);
        setUploadStatus('failed');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const { data } = useTagsObjectQuery({
    variables: {
      xTenantId,
      externalResourceId: userProfileId,
      externalResource: 'UserProfileId',
    },
  });

  const hasRequiredTag = (tags: TagsObjectQuery['tagsObject']): boolean => {
    if (!tags) return false;

    return tags.some((tagObj) => tagObj.tag?.name === '2KDOCSREQUIRED' || tagObj.tag?.name === '2KNotice');
  };

  useEffect(() => {
    if (currentStep === 'address' && !addressVerificationInitialized && addressVerificationRef.current) {
      initializeAddressVerification();
    }
  }, [currentStep, addressVerificationRef]);

  const initializeAddressVerification = async (): Promise<void> => {
    console.log('[AC9124] init', addressVerificationRef.current);

    if (window.AC9124 && addressVerificationRef.current) {
      const config: AddressVerificationConfig = {
        merchantkey:
          process.env.REACT_APP_AC_MERCHANT_KEY || 'mx1xVpE53j5Xn0RydF5rFsUr1EB3z5iqG1JMTMkFIAuiURCAlrCiguErEg6bPN5B',
        name: user?.givenName || '',
        surname: user?.familyName || '',
        street: user?.address?.streetAddress || '',
        city: user?.address?.addressLocality || '',
        postcode: user?.address?.postalCode || '',
        country: user?.address?.addressCountry || '',
        countrycode: 'GB',
        locale: 'en-GB',
        reference: userProfileId,
        onInit: (data) => {
          console.log('[AC9124]', 'onInit', data);
          setAddressVerificationInitialized(true);
          setIsAddressVerificationLoading(false);
        },
        onComplete: (data) => {
          console.log('[AC9124]', 'onComplete', data);
          setCurrentStep('complete');
          setUploadStatus('success');
        },
        onFail: (data) => {
          console.log('[AC9124]', 'onFail', data);
          setUploadStatus('failed');
        },
      };

      const ac9124 = new window.AC9124();

      ac9124.init(config);
      console.log('[AddressVerification] init: ', config);
    }
  };

  useEffect(() => {
    const hasCompletedUpload = localStorage.getItem(UPLOAD_COMPLETED_KEY) === 'true';
    const shouldDisplay =
      isDocUploaderEnabled &&
      compliance &&
      (compliance.kycLevel === 0 || (compliance.kycLevel === 1 && hasRequiredTag(data?.tagsObject))) &&
      !hasCompletedUpload;

    // Reset states if we shouldn't display
    if (!shouldDisplay) {
      setShowPrompt(false);
      setIsOpen(false);

      return;
    }

    // If we should display and prompt isn't shown yet, show it
    setShowPrompt(true);

    const fetchUrl = async (): Promise<void> => {
      setUploadStatus('loading');
      try {
        const url = await fetchUploaderIframeURL(userProfileId);

        setIframeUrl(url + '&embedded=true'); // or else iframe doesn't post messages
        setUploadStatus('initial');
      } catch (error) {
        setUploadStatus('failed');
      }
    };

    fetchUrl();
  }, [userProfileId, compliance, data?.tagsObject]);

  const handlePromptClose = (): void => {
    setShowPrompt(false);
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
    // Reset status when closing the modal
    setUploadStatus('initial');
    // set the localstorage flag, so we don't show again this session
    localStorage.setItem(UPLOAD_COMPLETED_KEY, 'true');
  };

  const onClickTryAgain = async (): Promise<void> => {
    setUploadStatus('loading');

    try {
      const url = await fetchUploaderIframeURL(userProfileId);

      setIframeUrl(url + '&embedded=true');
      setUploadStatus('initial');
    } catch (error) {
      setUploadStatus('failed');
    }
  };

  // If compliance check fails or user has already completed upload in this session, don't render anything
  if (
    !isDocUploaderEnabled ||
    !compliance ||
    !(compliance.kycLevel === 0 || (compliance.kycLevel === 1 && hasRequiredTag(data?.tagsObject))) ||
    localStorage.getItem(UPLOAD_COMPLETED_KEY) === 'true'
  ) {
    return null;
  }

  const renderContent = (): ReactNode => {
    // Handle success case first
    if (uploadStatus === 'success' && currentStep === 'complete') {
      return (
        <div className="doc-uploader__message doc-uploader__complete">
          <p>Document upload completed successfully!</p>
        </div>
      );
    }

    // Handle all other cases
    switch (uploadStatus) {
      case 'loading':
        return (
          <div className="spinner-wrapper">
            <LogoSpinner />
          </div>
        );
      case 'failed':
        return (
          <div className="doc-uploader__message doc-uploader__complete">
            <p>
              Verification failed! Please{' '}
              <Button type="button" variant="text" onClick={onClickTryAgain}>
                try again
              </Button>
            </p>
          </div>
        );
      default:
        if (currentStep === 'id') {
          return iframeUrl ? (
            <iframe
              ref={iframeRef}
              src={iframeUrl}
              title="ID Document Uploader"
              allow="camera; microphone"
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-downloads allow-top-navigation"
            />
          ) : (
            <div className="spinner-wrapper">
              <LogoSpinner />
            </div>
          );
        } else if (currentStep === 'address') {
          return (
            <div className="address-verification-container">
              {isAddressVerificationLoading && (
                <div className="spinner-wrapper spinner-wrapper--overlay">
                  <LogoSpinner />
                </div>
              )}
              <div id="ac-component" ref={addressVerificationRef} />
            </div>
          );
        }

        return null;
    }
  };

  const getModalTitle = (): string => {
    switch (currentStep) {
      case 'id':
        return 'ID Document Upload';
      case 'address':
        return 'Proof of Address Verification';
      default:
        return 'Document Verification';
    }
  };

  return (
    <>
      <div className="doc-uploader">
        <Prompt
          PrimaryButton={{ text: 'Start Verification', onClick: handlePromptClose }}
          show={showPrompt}
          title="Document Verification Required"
          onClose={handlePromptClose}
        >
          {t('documentUpload.prompt')}
        </Prompt>

        <PopUpHandler
          elementToFocusOnOpen={iframeRef}
          isOpen={isOpen}
          header={getModalTitle()}
          onClose={handleClose}
          modalProps={{ className: 'doc-uploader__modal' }}
          drawerProps={{ className: 'doc-uploader__modal' }}
        >
          {renderContent()}
        </PopUpHandler>
      </div>
    </>
  );
};
