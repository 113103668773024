import React, { FunctionComponent } from 'react';
import { Spinner } from '../../../../shared/Spinner';
import { PromotionProgressBar } from '../../../../Layout/Promotions/PromotionProgressBar';
import './styles';
import { ParsedPromo } from '../../../../Layout/Promotions/types';

export type BonusItemProps = {
  index: number;
  promotion: ParsedPromo;
  isFetching: boolean;
};

export const BonusItem: FunctionComponent<BonusItemProps> = ({ promotion, isFetching }) => {
  const searchGamesByTag = (): void => {
    console.log('searchGamesByTag', searchGamesByTag);
  };

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : (
        <div
          className="balance-details__item"
          onClick={(): void => searchGamesByTag()}
          onKeyPress={(): void => searchGamesByTag()}
          role="button"
          tabIndex={0}
        >
          <PromotionProgressBar promotion={promotion} />
        </div>
      )}
    </>
  );
};
