import { useNavigate } from 'react-router-dom';
import { Button } from '../../../shared/Button';
import { Heading } from '../../../shared/Heading';
import { Paragraph } from '../../../shared/Paragraph';
import { RegistrationStepComponent } from '../../types';
import CompletedAnimation from './CompletedAnimation';
import { APP_URLS } from '../../../../consts';
import './RegistrationIntroductionStep.scss';
import { capitalizeFirstLetter } from '../../../../utils/form';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

// TODO: update this component
/**
 * A registration step component for introduction screen!
 */
export const RegistrationIntroductionStep: RegistrationStepComponent = ({ stepConfig, selected, ...props }) => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  if (!selected) return null;

  const onContinueClick = (): void => {
    navigate(APP_URLS.home);
    console.log('onContinueClick');
  };

  return (
    <div id={stepConfig.id} className="registration-introduction-step">
      <Heading level={6}>
        {t('register.success.welcome', {
          name: capitalizeFirstLetter(props.formState.givenName),
        })}
      </Heading>
      <Paragraph>{t('register.success.greetings.label')}</Paragraph>
      <CompletedAnimation />
      <div className="registration-introduction-step__buttons">
        <Button onClick={onContinueClick} type="button" variant="primary" size="large">
          {t('register.success.home.continue')}
        </Button>
      </div>
    </div>
  );
};
