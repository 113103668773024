import React, { FC } from 'react';
import { Casino } from '../../../components/Games';
import { NavigationTemplate } from '../../NavigationTemplate';
import { useLocation } from 'react-router-dom';
import { SEO } from '../../../components/seo/SEO';

export const GameDetailsPage: FC = (): JSX.Element => {
  const location = useLocation();

  return (
    <NavigationTemplate>
      <SEO canonicalPath={location.pathname} />
      <Casino gameEnabled />
    </NavigationTemplate>
  );
};
