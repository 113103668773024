import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const NoIndex: FC = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
      <meta name="googlebot" content="noindex, nofollow" />
      <meta httpEquiv="X-Robots-Tag" content="noindex, nofollow" />
    </Helmet>
  );
};
