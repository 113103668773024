export enum CashierType {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
}

export type CashierModal = {
  isOpen: boolean;
  type?: CashierType;
  promoCode?: string;
  promoDepositAmount?: number;
  promoTag?: string;
};

export interface CashierState {
  cashierModal: CashierModal;
}
