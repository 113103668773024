export interface IUseUserBalanceData {
  mainCashBalance: number;
  lockedCashBalance: number;
  bonusBalance: number;
}

export enum BalanceTypes {
  mainCashBalance = 'mainCashBalance',
  lockedCashBalance = 'lockedCashBalance',
  bonusBalance = 'bonusBalance',
}

export interface IUseUserBalanceReturnType {
  isBalanceLoading: boolean;
  userBalance: IUseUserBalanceData;
}

export enum USER_BALANCE_TYPE {
  MAIN = 'main',
  LOCKED = 'locked',
  BONUS = 'bonus',
  FREEBET = 'free-bet',
  FREESPINS = 'free-spins',
}
