import React from 'react';
import { Helmet } from 'react-helmet';

// Base website info
const SITE_NAME = 'Betzone';
const BASE_URL = 'https://betzone.co.uk';
const DEFAULT_IMAGE = '/betzone-social.png';

interface SEOProps {
  title?: string;
  description?: string;
  path?: string;
  image?: string;
  type?: 'website' | 'article';
  keywords?: string[];
  author?: string;
  publishedAt?: string;
  canonicalPath?: string;
}

/**
 * SEO component to enhance page discoverability
 */
export const SEO: React.FC<SEOProps> = ({
  title,
  description,
  path,
  image = DEFAULT_IMAGE,
  type = 'website',
  keywords = [],
  author = SITE_NAME,
  publishedAt,
  canonicalPath,
}) => {
  const url = path ? `${BASE_URL}${path}` : BASE_URL;
  const fullTitle = title ? `${title} | ${SITE_NAME}` : SITE_NAME;
  const fullImageUrl = image;
  const canonicalUrl = canonicalPath ? `${BASE_URL}${canonicalPath}` : undefined;

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      {title && <title>{fullTitle}</title>}
      {description && <meta name="description" content={description} />}
      {keywords?.length > 0 && <meta name="keywords" content={keywords.join(', ')} />}

      {/* Canonical URL */}
      {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : <link rel="canonical" href={BASE_URL} />}

      {/* Open Graph */}
      {title && <meta property="og:title" content={fullTitle} />}
      {description && <meta property="og:description" content={description} />}
      <meta property="og:type" content={type} />
      {path && <meta property="og:url" content={url} />}
      {image && <meta property="og:image" content={fullImageUrl} />}
      <meta property="og:site_name" content={SITE_NAME} />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      {title && <meta name="twitter:title" content={fullTitle} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={fullImageUrl} />}

      {/* Article Specific */}
      {type === 'article' && author && <meta property="article:author" content={author} />}
      {type === 'article' && publishedAt && <meta property="article:published_time" content={publishedAt} />}
    </Helmet>
  );
};
