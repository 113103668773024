import React, { FunctionComponent, useState } from 'react';

import { Game, PaginatedResponse } from '../../../Games/games.model';
import { PromotionProgressBar } from '../PromotionProgressBar';
import { GameSliderPrompt } from '../../../Games/GameSliderPrompt/GameSliderPrompt';
import { Icon } from '../../../shared/Icon';
import { Spinner } from '../../../shared/Spinner';
import { featuredGamesResponse } from '../../../Games/mocks';
import { ParsedPromo } from '../types';

export type PromotionBonusBalanceItemProps = {
  promotion: ParsedPromo;
  index: number;
  isFetching: boolean;
  onCloseWindow?: () => void;
};

export const PromotionBonusBalanceItem: FunctionComponent<PromotionBonusBalanceItemProps> = ({
  promotion,
  index,
  isFetching,
  onCloseWindow,
}) => {
  const [isOpenPopUp, setOpenPopUp] = useState<boolean>(false);
  const [gamesByTag] = useState<PaginatedResponse<Game>>(featuredGamesResponse());
  const isFreeBet = promotion.isFreeBet;
  const searchGamesByTag = async (index: number): Promise<void> => {
    // TODO: Implement getGameList
    setOpenPopUp(!isFreeBet);
  };

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : (
        <div
          className="promotion-progress-bar"
          onClick={(): Promise<void> => searchGamesByTag(index)}
          role="button"
          tabIndex={0}
        >
          <PromotionProgressBar promotion={promotion} />
          <span className="promotion-progress-bar__summary-expand">
            <Icon variant={isFreeBet ? 'IoIosFootball' : 'ChevronRight'} />
          </span>
        </div>
      )}
      <GameSliderPrompt
        promotion={promotion}
        gamesByTag={gamesByTag}
        openPopUp={isOpenPopUp}
        setOpenPopUp={setOpenPopUp}
        onCloseWindow={onCloseWindow}
      />
    </>
  );
};
