import { useEffect, useState } from 'react';

const CompletedAnimation = ({ size = 100, color = '#22c55e' }): JSX.Element => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // Small delay to ensure component is mounted before animation starts
    const timer = setTimeout(() => {
      setIsPlaying(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ display: 'inline-block', margin: '1rem' }}>
      <svg viewBox="0 0 100 100" width={size} height={size}>
        {/* Circle */}
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke={color}
          strokeWidth="7.5"
          style={{
            strokeDasharray: '283',
            strokeDashoffset: isPlaying ? '0' : '283',
            transition: 'stroke-dashoffset 0.8s ease-out 0.6s',
          }}
        />

        {/* Checkmark */}
        <path
          d="M25,55 L45,70 L75,35"
          fill="none"
          stroke={color}
          strokeWidth="7.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{
            strokeDasharray: '76',
            strokeDashoffset: isPlaying ? '0' : '76',
            transition: 'stroke-dashoffset 0.5s ease-out',
          }}
        />
      </svg>
    </div>
  );
};

export default CompletedAnimation;
