import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BonusesGetAllQueryVariables = Types.Exact<{
  language: Types.Scalars['String']['input'];
}>;


export type BonusesGetAllQuery = { __typename?: 'Query', bonus: Array<{ __typename?: 'bonus', template_id?: string | null, dm_template_id?: string | null, dm_max_amount?: number | null, start_date?: any | null, end_date?: any | null, collection?: { __typename?: 'bonus_collections', translations?: Array<{ __typename?: 'bonus_collections_translations', display_name?: string | null } | null> | null } | null, translations?: Array<{ __typename?: 'bonus_translations', title?: string | null, subtitle?: string | null, description?: string | null, slug?: string | null, thumbnail?: { __typename?: 'directus_files', filename_disk?: string | null, title?: string | null, width?: number | null, height?: number | null } | null } | null> | null }> };


export const BonusesGetAllDocument = gql`
    query BonusesGetAll($language: String!) {
  bonus(
    filter: {status: {_eq: "published"}, translations: {languages_language_code: {language_code: {_eq: $language}}}}
  ) {
    template_id
    dm_template_id
    dm_max_amount
    start_date
    end_date
    collection(filter: {status: {_eq: "published"}}) {
      translations(
        filter: {languages_language_code: {language_code: {_eq: $language}}}
      ) {
        display_name
      }
    }
    translations(
      filter: {languages_language_code: {language_code: {_eq: $language}}}
    ) {
      title
      subtitle
      description
      slug
      thumbnail(filter: null) {
        filename_disk
        title
        width
        height
      }
    }
  }
}
    `;

/**
 * __useBonusesGetAllQuery__
 *
 * To run a query within a React component, call `useBonusesGetAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonusesGetAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonusesGetAllQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useBonusesGetAllQuery(baseOptions: Apollo.QueryHookOptions<BonusesGetAllQuery, BonusesGetAllQueryVariables> & ({ variables: BonusesGetAllQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BonusesGetAllQuery, BonusesGetAllQueryVariables>(BonusesGetAllDocument, options);
      }
export function useBonusesGetAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BonusesGetAllQuery, BonusesGetAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BonusesGetAllQuery, BonusesGetAllQueryVariables>(BonusesGetAllDocument, options);
        }
export function useBonusesGetAllSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BonusesGetAllQuery, BonusesGetAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BonusesGetAllQuery, BonusesGetAllQueryVariables>(BonusesGetAllDocument, options);
        }
export type BonusesGetAllQueryHookResult = ReturnType<typeof useBonusesGetAllQuery>;
export type BonusesGetAllLazyQueryHookResult = ReturnType<typeof useBonusesGetAllLazyQuery>;
export type BonusesGetAllSuspenseQueryHookResult = ReturnType<typeof useBonusesGetAllSuspenseQuery>;
export type BonusesGetAllQueryResult = Apollo.QueryResult<BonusesGetAllQuery, BonusesGetAllQueryVariables>;