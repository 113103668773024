import React, { FunctionComponent, useRef, useState } from 'react';
import throttle from 'lodash/fp/throttle';
import { BonusItem } from './HeaderBalanceBonus';
import { joinStrings } from '../../../../utils/string';
import { formatCurrency } from '../../../../utils/wallets';
import { ParsedPromo } from '../../../Layout/Promotions/types';
import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { BonusIcon, CashIcon } from '../../../shared/SVG/Icons';
import { RewardItem, RewardItemProps } from './HeaderBalanceReward';
import { PromotionTabsType } from '../../../Layout/Promotions/PromotionsList/types';
import { useFetchMergedPromotions } from '../../../../hooks/useFetchMergedPromotions';
import './styles';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { Button } from '../../../shared/Button';
import { Icon } from '../../../shared/Icon';
import { useNavigate } from 'react-router-dom';
import { APP_URLS } from '../../../../consts';
import { useLocation } from 'react-router-dom';

// TODO: implement logic for bonuses
export const HeaderBalanceDetails: FunctionComponent<{
  mainCashBalance: number;
  lockedCashBalance: number;
  bonusBalance: number;
  isEmbedded?: boolean;
  showBonusDetails?: boolean;
}> = ({ isEmbedded, showBonusDetails = true, bonusBalance, mainCashBalance, lockedCashBalance }) => {
  const { t } = useTranslations();

  const [showSummary, setShowSummary] = useState<unknown>(false);

  const scrollRef = useRef<HTMLDivElement>(null);
  const expandedItems = useRef<number>(0);

  const { mergedFilteredPromotions, isPromosLoading } = useFetchMergedPromotions(PromotionTabsType.ACTIVE);

  // TODO: Replace the mock with actual data
  const rewards: Array<RewardItemProps> = [];

  const navigate = useNavigate();
  const location = useLocation();
  const isPromotionsPage = location.pathname === APP_URLS.promotions;

  const onScroll = throttle(250, () => {
    if (scrollRef && scrollRef.current) {
      if (scrollRef.current.scrollTop > 0) {
        setShowSummary(true);
      } else if (scrollRef.current.scrollTop === 0) {
        setShowSummary(false);
      }
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onItemExpand = (state: boolean): void => {
    expandedItems.current += state ? 1 : -1;
    const showState = expandedItems.current > 0;

    setShowSummary(showState);

    if (scrollRef && scrollRef.current && !showState) {
      scrollRef.current.scrollTo(0, 0);
    }
  };

  const onClickMoreInfo = (): void => {
    navigate(APP_URLS.promotions);
  };

  return (
    <>
      <div className={`balance-details__summary${showSummary ? ' show' : ''}`}>
        <div className="balance-details__summary-cash">
          <span className="balance-details__card-header-icon">
            <CashIcon />
          </span>
          <span className="balance-details__card-header-amount">
            {formatCurrency(mainCashBalance + lockedCashBalance, 'auto', 'auto')}
          </span>
        </div>
        <div className="balance-details__summary-bonus">
          <span className="balance-details__card-header-icon">
            <BonusIcon />
          </span>
          <span className="balance-details__card-header-amount">{formatCurrency(bonusBalance, 'auto', 'auto')}</span>
        </div>
      </div>

      <div
        ref={scrollRef}
        onScroll={onScroll}
        className={joinStrings([
          `balance-details__details${isEmbedded ? '-embedded' : ''}`,
          useDeviceInfo().isIos && 'balance-details__details--ios',
        ])}
        body-scroll-lock-ignore="true"
        data-test-id="header-balance-details"
      >
        <div className="balance-details__card balance-details__cash">
          <div
            className={joinStrings([
              'balance-details__card-header',
              isEmbedded && 'balance-details__card-header--embedded',
            ])}
          >
            <span className="balance-details__card-header-icon">
              <CashIcon />
            </span>
            <span className="balance-details__card-header-text">{t('balance.cash')}</span>
            <span className="balance-details__card-header-amount">
              {formatCurrency(mainCashBalance, 'auto', 'auto')}
            </span>
          </div>
          {lockedCashBalance > 0 && (
            <div className="balance-details__card-locked">
              <div className="balance-details__card-locked-header">
                <span className="balance-details__card-locked-text">{t('balance.locked')}</span>
                <span className="balance-details__card-locked-amount">
                  {formatCurrency(lockedCashBalance, 'auto', 'auto')}
                </span>
              </div>
              <div className="balance-details__card-locked-content">
                <p className="balance-details__card-locked-description">{t('balance.locked-desc')}</p>
                {!isPromotionsPage && (
                  <Button onClick={onClickMoreInfo} type="button" variant="text">
                    More Info
                    <Icon variant="ChevronRight"></Icon>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="balance-details__card balance-details__bonus">
          <div className="balance-details__card-header">
            <span className="balance-details__card-header-icon">
              <BonusIcon />
            </span>
            <span className="balance-details__card-header-text">{t('balance.bonus')}</span>
            <span className="balance-details__card-header-amount">{formatCurrency(bonusBalance, 'auto', 'auto')}</span>
          </div>
          {!!(rewards.length || mergedFilteredPromotions.length) && showBonusDetails && (
            <div className="balance-details__card-body">
              {(rewards as RewardItemProps[]).map((props, index) => (
                <RewardItem {...props} key={`balance-reward-item-${index}`} onExpand={onItemExpand} />
              ))}
              {mergedFilteredPromotions &&
                (mergedFilteredPromotions as unknown as ParsedPromo[])
                  .filter((promotion) => promotion?.balanceAmount)
                  .map((promotion, index) => (
                    <BonusItem
                      promotion={promotion}
                      key={`balance-bonus-item-${index}`}
                      isFetching={isPromosLoading}
                      index={index}
                    />
                  ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
