import { Transactions } from '../../../models/transactions.model';
import { addMonths } from 'date-fns';
import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { MyAccountActivityIcon } from './MyAccountActivityIcons';
import { MyAccountActivityPanel } from './MyAccountActivityPanel';
import { MyAccountActivityContent } from './MyAccountActivityContent';
import { MyAccountActivityProps } from './types';
import { DateRange, DateRangePayload } from '../../shared/DateRange';
import { Heading } from '../../shared/Heading';
import { TabularDatalist } from '../../shared/TabularDatalist';
import { useSnackbar } from '../../../hooks/snackbar';
import { AccountPageWrapper } from '../MyAccount/AccountPageWrapper';
import { TabularDataDetailsItem } from '../../shared/TabularDatalist/TabularDataDetailsItem';
import { useUserContext } from '../../../providers/UserProvider';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import { useUserTransactionsLazyQuery } from '../../../libs/graphql/baseAppAPI/queries/__generated__/user-transactions.query.generated';
import { xTenantId } from '../../../consts';
import { walletTypeNames } from './consts';
import { Paragraph } from '../../shared/Paragraph';
import './styles';
import { Chip } from '../../shared/Chip';
import { Icon } from '../../shared/Icon';

export const MyAccountActivity: FC<MyAccountActivityProps> = (props) => {
  const { t } = useTranslations();
  const { addSnack } = useSnackbar();
  const { userProfileId } = useUserContext();

  const [allTransactions, setAllTransactions] = useState<Transactions>([]);
  const [transactions, setTransactions] = useState<Transactions | undefined>([]);
  const [endCursor, setEndCursor] = useState<string>('');
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [resultTransactions, setResultTransactions] = useState<Transactions>([]);
  const totalTransactions = resultTransactions?.length || 0;
  const [dateRange, setDateRange] = useState({
    startDate: addMonths(new Date(), -1),
    endDate: new Date(),
  });
  const [mediatorTypeFilter, setMediatorTypeFilter] = useState<string>('');

  const [fetchTransactions, { data: transactionsData, loading: transactionsLoading, error: transactionsError }] =
    useUserTransactionsLazyQuery({
      variables: {
        inTenantId: xTenantId,
        inUserProfileId: userProfileId,
        inDatefromEpoch: dateRange.startDate.setHours(0, 0, 0, 0),
        inDatetoEpoch: dateRange.endDate.setHours(23, 59, 59, 999),
        after: endCursor,
        first: 50,
      },
      fetchPolicy: 'network-only',
    });

  /**
   * Fetches transactions for display in the table.
   * @param viaViewMore Options for different fetch contexts
   */
  const handleTransactions = (): void => {
    if (!transactions) return;

    // Add new transactions to our master list (similar to original, but without filtering)
    const newTransactions = [...allTransactions];

    transactions.forEach((transaction) => {
      // Only check for duplicates based on ID
      if (!newTransactions.some((t) => t?.transactionId === transaction?.transactionId)) {
        newTransactions.push(transaction);
      }
    });

    // Update the master list
    setAllTransactions(newTransactions);
  };

  const getFilteredTransactions = (): Transactions => {
    return allTransactions.filter((transaction) => {
      const hasAmount = transaction?.transactionAmount;
      const matchesMediator = mediatorTypeFilter === '' || transaction?.mediatorType === mediatorTypeFilter;

      return hasAmount && matchesMediator;
    });
  };

  const refetchTransactions = (): void => {
    const endCursor = transactionsData?.userTransactions?.pageInfo.endCursor;

    setEndCursor(endCursor);
  };

  const clearTransactions = (): void => {
    setResultTransactions([]);
    setAllTransactions([]);
  };

  const assignDate = ({ startDate, endDate }: DateRangePayload): void => {
    if (startDate && endDate) {
      clearTransactions();
      setDateRange({ startDate, endDate });
      setEndCursor('');
    }
  };

  const tabularDataset: { content: ReactElement<string, string>; panel: ReactElement<string, string> }[] | undefined =
    useMemo(
      () =>
        resultTransactions?.map((transaction) => {
          const {
            previousBalanceAmount,
            balanceAmount,
            transactionAmount,
            transactionStatus,
            lastUpdatedOn,
            createdOn,
            walletType,
            walletId,
            mediatorType,
            operationType,
            mediatorId,
          } = transaction || {};

          const payout = Math.abs(Number(previousBalanceAmount) - Number(balanceAmount));

          const description = `${t(
            walletType === walletTypeNames.main ? 'my-account.cache-wallet' : 'my-account.bonus-wallet'
          )} - ${mediatorType?.replace(/^./, (str) => str.toUpperCase())}`;

          const getTransactionTitle = (operationType: string, mediatorType: string, mediatorId: string): string => {
            // TODO: use translations
            if (mediatorId === 'endeavour-manual-adjustments') {
              return 'Manual Adjustment';
            }

            if (operationType === 'Credit') {
              switch (mediatorType.toLowerCase()) {
                case 'sports':
                  return 'Sports Cashout/Win';
                case 'casino':
                  return 'Casino Win';
                case 'monetary':
                  return 'Deposit';
                default:
                  return 'Account Credit';
              }
            } else if (operationType === 'Debit') {
              switch (mediatorType.toLowerCase()) {
                case 'sports':
                  return 'Sports Bet';
                case 'casino':
                  return 'Casino Bet';
                case 'monetary':
                  return 'Withdrawal';
                default:
                  return 'Account Debit';
              }
            }

            return 'Transaction';
          };

          const transactionTitle = getTransactionTitle(String(operationType), String(mediatorType), String(mediatorId));

          return {
            content: (
              <MyAccountActivityContent
                description={description}
                couponRef={String(transaction?.transactionId)}
                transactionAmount={Number(transactionAmount)}
                content={<TabularDataDetailsItem {...props} key={walletId} eventStartDate={String(createdOn)} />}
                payout={payout}
              />
            ),
            panel: (
              <MyAccountActivityPanel
                title={transactionTitle}
                status={String(transactionStatus)}
                amount={Number(transactionAmount)}
                transactionDate={String(lastUpdatedOn)}
                cashBalanceAfter={previousBalanceAmount}
                walletType={String(walletType)}
                ActivityIcon={
                  <MyAccountActivityIcon operationType={String(operationType)} mediatorType={String(mediatorType)} />
                }
              />
            ),
          };
        }),
      [resultTransactions]
    );

  useEffect(() => {
    fetchTransactions();
  }, [dateRange]);

  useEffect(() => {
    transactionsError &&
      addSnack({
        type: 'error',
        message: t('my-account.home.my-activity.transaction-handling-error'),
      });
  }, [transactionsError]);

  useEffect(() => {
    const cleanTransactions = transactionsData?.userTransactions?.edges.map((edgeItem) => edgeItem.node);
    const hasNextPage = Boolean(transactionsData?.userTransactions?.pageInfo.hasNextPage);

    setTransactions(cleanTransactions);
    setHasNextPage(hasNextPage);
  }, [transactionsData]);

  useEffect(() => {
    transactions?.length && handleTransactions();
  }, [transactions]);

  useEffect(() => {
    // Update filtered results whenever filter changes or we get new transactions
    setResultTransactions(getFilteredTransactions());
  }, [mediatorTypeFilter, allTransactions]);

  return (
    <>
      <AccountPageWrapper {...props} className="my-activity">
        <div className="my-account-activity__heading">
          <Heading level={3}>{t('my-account.my-activity')}</Heading>

          <div className="my-account-activity__daterange">
            <DateRange
              dateFormat="dd/MMM/yyyy"
              startDateTitle={t('my-account.home.my-activity.start-date')}
              endDateTitle={t('my-account.home.my-activity.end-date')}
              startingDate={dateRange.startDate}
              endingDate={dateRange.endDate}
              onAction={assignDate}
              isCustomHeader
              inputBoxClassName="my-account-activity__date-range"
            />
          </div>
          <div className="my-account-activity__chips">
            <Chip
              variant="rounded"
              className={mediatorTypeFilter === 'monetary' ? 'active' : ''}
              onClick={(): void => {
                setMediatorTypeFilter(mediatorTypeFilter !== 'monetary' ? 'monetary' : '');
              }}
            >
              <Icon variant="IoWalletOutline" />
              Banking
            </Chip>
            <Chip
              variant="rounded"
              className={mediatorTypeFilter === 'sports' ? 'active' : ''}
              onClick={(): void => {
                setMediatorTypeFilter(mediatorTypeFilter !== 'sports' ? 'sports' : '');
              }}
            >
              <Icon variant="IoIosFootball" />
              Sports
            </Chip>
            <Chip
              variant="rounded"
              className={mediatorTypeFilter === 'casino' ? 'active' : ''}
              onClick={(): void => {
                setMediatorTypeFilter(mediatorTypeFilter !== 'casino' ? 'casino' : '');
              }}
            >
              <Icon variant="IoDiceOutline" />
              Casino
            </Chip>
          </div>
        </div>

        <div className="my-account-activity__content">
          <div className="my-account-activity__data">
            <div className="my-account-activity__transactions-wrapper">
              <TabularDatalist
                showCount
                isLoading={transactionsLoading}
                onLoadMore={refetchTransactions}
                showLoadMore={hasNextPage}
                totalResults={totalTransactions}
                dataset={tabularDataset}
              />
            </div>
            {!(hasNextPage || transactionsLoading) && (
              <Paragraph className="my-account-activity__limit-warning">
                {t('my-account.home.my-activity.history.limit')}
              </Paragraph>
            )}
          </div>
        </div>
      </AccountPageWrapper>
    </>
  );
};
