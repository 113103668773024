import React, { FunctionComponent, KeyboardEventHandler, Suspense, useEffect, useState } from 'react';
import { HeaderBalanceDetails } from './HeaderBalanceDetails';
import { joinStrings } from '../../../utils/string';
import { Icon } from '../../shared/Icon';
import { BalanceDrawer } from '../BalanceDrawer';
import { Spinner } from '../../shared/Spinner';
import { useUserBalance } from '../../../hooks/useUserBalance';
import { Balance } from '../../shared/Balance';
import './styles';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

type HeaderBalanceProps = {
  hideBalanceDrawer: boolean;
  showBonusDetails?: boolean;
  isEmbedded?: boolean;
  setHideBalanceDrawer: (argument: boolean) => void;
  setBalanceClicked: (argument: boolean) => void;
};

// TODO: update this component
export const HeaderBalance: FunctionComponent<HeaderBalanceProps> = ({
  hideBalanceDrawer,
  isEmbedded,
  setHideBalanceDrawer,
  setBalanceClicked,
  showBonusDetails,
}) => {
  const { t } = useTranslations();

  const [open, openSet] = useState(false);
  const { isBalanceLoading, userBalance } = useUserBalance();

  const balanceLoaded = true;
  const embededClassName = isEmbedded ? 'header-balance__text-embedded' : '';

  useEffect(() => {
    hideBalanceDrawer && openSet(false);
    setHideBalanceDrawer(false);
  }, [hideBalanceDrawer, setHideBalanceDrawer]);

  const toggleBalance: () => void = () => {
    if (!open) {
      window.scrollTo(0, 0);
      setBalanceClicked(false);
    }

    openSet(!open);
  };

  const onKeyPressBalance: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      openSet(true);
    }

    if (e.key === 'Escape') {
      openSet(false);
    }

    !open && setBalanceClicked(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onCloseHeader = (): void => {
    openSet(false);
  };

  return (
    <>
      <div
        tabIndex={0}
        className={joinStrings(['header-balance', open && 'header-balance--open'])}
        data-testid="header-balance"
        onClick={toggleBalance}
        onKeyPress={onKeyPressBalance}
        role="button"
      >
        {isBalanceLoading ? (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        ) : (
          balanceLoaded && (
            <div className={embededClassName}>
              <span className="header-balance__text">
                {t('balance.my-balance')}
                <Icon variant={open ? 'ChevronUp' : 'ChevronDown'} />
              </span>
              <Balance
                isLoading={isBalanceLoading}
                className="header-balance__sum"
                balance={userBalance.mainCashBalance + userBalance.lockedCashBalance}
              />
            </div>
          )
        )}

        {isEmbedded && open && (
          <Suspense>
            <HeaderBalanceDetails
              isEmbedded={isEmbedded}
              showBonusDetails={showBonusDetails}
              mainCashBalance={userBalance.mainCashBalance}
              lockedCashBalance={userBalance.lockedCashBalance}
              bonusBalance={userBalance.bonusBalance}
            />
          </Suspense>
        )}
      </div>
      {/* Do not remove "open &&" structure as it causes unexpected rerender */}
      {!isEmbedded && open && (
        <div>
          <BalanceDrawer show onClose={onCloseHeader}>
            <HeaderBalanceDetails
              isEmbedded={isEmbedded}
              showBonusDetails={showBonusDetails}
              mainCashBalance={userBalance.mainCashBalance}
              lockedCashBalance={userBalance.lockedCashBalance}
              bonusBalance={userBalance.bonusBalance}
            />
          </BalanceDrawer>
        </div>
      )}
    </>
  );
};
